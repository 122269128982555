<template>
  <div class="home bgrnd has-text-black mx-0">
    <div class="section has-background-black has-text-white  mx-0">
      <div class="is-size-5">
        <b>Just The News</b>
      </div>
    </div>
    <br>

      <div class="column mx-1">
        <div class="mx-2" style="">
          <br>
          <b>JTN Active Headlines</b>
          <br><br>
          <table class="table is-hoverable is-bordered is-striped is-hoverable is-fullwidth" >
            <thead>
                <tr>
                  <th class="has-text-centered" style="width:30%">Header</th>
                  <th class="has-text-centered">Twitter <br> JS</th>
                  <th class="has-text-centered">Twitter <br> JTN</th>
                  <th class="has-text-centered">Truth <br> JS</th>
                  <th class="has-text-centered">Truth <br> JTN</th>
                  <th class="has-text-centered">FB <br> JS</th>
                  <th class="has-text-centered">FB <br> JTN</th>
                  <th class="has-text-centered">DeQueue</th>
                  <th>Submit </th>
                </tr>
              </thead>
              <template v-for="post in activeblock" >
                <tbody>
                  <tr>
                    <td class="has-text-left">
                    <textarea class="input textarea" type="text" placeholder="post.header" v-model="post.header" rows="2"></textarea>
                    </td>
                    <td>
                      <div class="control columns">
                      <div class="column">
                      <label class="radio"><input type="radio" name="twjs" value="Y"  v-model="post.twjs">&nbsp Y</label>
                      <label class="radio"><input type="radio" name="twjs" value="-"  v-model="post.twjs">&nbsp N</label>
                      </div>
                      <div class="column">
                      &nbsp&nbsp<b>{{post.twjs}}</b><br>
                      <a v-bind:href="post.index+'?utm_source=sf&utm_medium=social&utm_campaign=twjs'" class="is-size-7" >link</a>
                      </div>
                      </div>
                    </td>
                    <td>
                      <div class="control columns">
                      <div class="column">
                      <label class="radio"><input type="radio" name="twjtn" value="Y"  v-model="post.twjtn">&nbsp Y</label>
                      <label class="radio"><input type="radio" name="twjtn" value="-"  v-model="post.twjtn">&nbsp N</label>
                      </div>
                      <div class="column">
                      &nbsp&nbsp<b>{{post.twjtn}} </b>
                      <br>
                      <a v-bind:href="post.index+'?utm_source=sf&utm_medium=social&utm_campaign=twjtn'" class="is-size-7" >link</a>
                      </div>
                      </div>
                    </td>

                    <td>
                      <div class="control columns">
                      <div class="column">
                        <label class="radio"><input type="radio" name="trujs" value="Y"  v-model="post.trujs">&nbsp Y</label>
                        <label class="radio"><input type="radio" name="trujs" value="-"  v-model="post.trujs">&nbsp N</label>
                      </div>
                      <div class="column">
                      &nbsp&nbsp<b>{{post.trujs}} </b>
                      <br>
                      <a v-bind:href="post.index+'?utm_source=tr&utm_medium=social&utm_campaign=trjs'" class="is-size-7" >link</a>
                      </div>
                      </div>
                    </td>
                    <td>
                      <div class="control columns">
                      <div class="column">
                        <label class="radio"><input type="radio" name="trujtn" value="Y"  v-model="post.trujtn">&nbsp Y</label>
                        <label class="radio"><input type="radio" name="trujtn" value="-"  v-model="post.trujtn">&nbsp N</label>
                      </div>
                      <div class="column">
                        &nbsp&nbsp<b>{{post.trujtn}} </b>
                        <br>
                      <a v-bind:href="post.index+'?utm_source=tr&utm_medium=social&utm_campaign=trjtn'" class="is-size-7" >link</a>
                      </div>
                      </div>
                    </td>
                    <td>
                      <div class="control columns">
                      <div class="column">
                        <label class="radio"><input type="radio" name="fbjs" value="Y"  v-model="post.fbjs">&nbsp Y</label>
                        <label class="radio"><input type="radio" name="fbjs" value="-"  v-model="post.fbjs">&nbsp N</label>
                      </div>
                      <div class="column">
                        &nbsp&nbsp<b>{{post.fbjs}} </b>
                        <br>
                      <a v-bind:href="post.index+'?utm_source=sf&utm_medium=social&utm_campaign=fbjs'" class="is-size-7" >link</a>
                    </div>
                    </div>
                    </td>
                    <td>
                      <div class="control columns">
                      <div class="column">
                        <label class="radio"><input type="radio" name="fbjtn" value="Y"  v-model="post.fbjtn">&nbsp Y</label>
                        <label class="radio"><input type="radio" name="fbjtn" value="-"  v-model="post.fbjtn">&nbsp N</label>
                      </div>
                      <div class="column">
                        &nbsp&nbsp<b>{{post.fbjtn}} </b>
                      <br>
                      <a v-bind:href="post.index+'?utm_source=sf&utm_medium=social&utm_campaign=fbjtn'" class="is-size-7" >link</a>
                      </div>
                      </div>
                    </td>
                    <td>
                      <div class="control">
                        <label class="radio"><input type="radio" name="close" value="Y"  v-model="post.close">&nbsp Y</label><br>
                        <label class="radio"><input type="radio" name="close" value="-"  v-model="post.close">&nbsp N</label>
                      </div>
                    </td>
                    <td>
                      <button class="button is-small is-success" @click="submitPost(post)">Update</button>
                      <br>
                      <input type="checkbox" v-model="post.dup"> Copy
                    </td>  
                  </tr>
                </tbody>
            </template>
          </table>
        </div>
      </div>

      <br><br>


      <div class="column is-11 mx-1" style="">
        <div >
          <br>
          <b>Posted Headlines</b>
          <br><br>
          <table class="table is-hoverable is-bordered is-striped is-hoverable is-fullwidth" >
            <thead>
                <tr>
                  <th class="has-text-centered" style="width:30%">Header</th>
                  <th class="has-text-centered">Twitter <br> JS</th>
                  <th class="has-text-centered">Twitter <br> JTN</th>
                  <th class="has-text-centered">Truth <br> JS</th>
                  <th class="has-text-centered">Truth <br> JTN</th>
                  <th class="has-text-centered">FB <br> JS</th>
                  <th class="has-text-centered">FB <br> JTN</th>
                  <th class="has-text-centered">Close</th>
                  <th>Submit </th>
                </tr>
              </thead>
              <template v-for="post in postedblock" >
                <template v-if ="openposts.includes(post.id)">
                  <tbody>
                    <tr>
                      <td class="has-text-left">
                      <a v-bind:href="post.index" class="has-text-black" target="_blank" v-html="post.header"></a>  
                      </td>
                      <td><input type="text" name="twjs" class="input"  v-model="post.twjs">
                        <a v-bind:href="post.index+'?utm_source=sf&utm_medium=social&utm_campaign=twjs'" class="is-size-7" >link</a>
                      </td>
                      <td><input type="text" name="twjtn" class="input"  v-model="post.twjtn">
                        <a v-bind:href="post.index+'?utm_source=sf&utm_medium=social&utm_campaign=twjtn'" class="is-size-7" >link</a>
                      </td>
                      <td><input type="text" name="trujs" class="input"  v-model="post.trujs">
                        <a v-bind:href="post.index+'?utm_source=tr&utm_medium=social&utm_campaign=trjs'" class="is-size-7" >link</a>
                      </td>
                      <td><input type="text" name="trujtn" class="input"  v-model="post.trujtn">
                        <a v-bind:href="post.index+'?utm_source=tr&utm_medium=social&utm_campaign=trjtn'" class="is-size-7" >link</a>
                      </td>
                      <td><input type="text" name="fbjs" class="input"  v-model="post.fbjs">
                        <a v-bind:href="post.index+'?utm_source=sf&utm_medium=social&utm_campaign=fbjs'" class="is-size-7" >link</a>
                      </td>
                      <td><input type="text" name="fbjtn" class="input"  v-model="post.fbjtn">
                        <a v-bind:href="post.index+'?utm_source=sf&utm_medium=social&utm_campaign=fbjtn'" class="is-size-7" >link</a>
                      </td>
                      <td><input type="text" name="close" class="input"  v-model="post.close"></td>
                      <td><button class="button is-small is-success" @click="submitPost(post)">Update</button>
                      <br>
                      <button class="button is-small is-warning" @click="updateRSS(post.id)">Close</button>
                      </td>  
                    </tr>
                  </tbody>
              </template>
            </template>
          </table>
        </div>
      </div>

      <br><br>

    <div class="column is-10  is-offset-1 has-background-white has-text-left is-size-7">
      <table class="table is-hoverable is-bordered is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Source</th>
            <th>Tracking Tag</th>
          </tr>
        </thead>
        <tbody>
          <tr >
            <td>Twitter JTN </td>
            <td>?utm_source=sf&utm_medium=social&utm_campaign=twjtn</td>
          </tr>
          <tr>
            <td>Twitter JS </td>
            <td>?utm_source=sf&utm_medium=social&utm_campaign=twjs</td>
          </tr>
          <tr>
            <td>Truth JTN </td>
            <td>?utm_source=tr&utm_medium=social&utm_campaign=trjtn</td>
          </tr>
          <tr>
            <td>Truth JS </td>
            <td>?utm_source=tr&utm_medium=social&utm_campaign=trjs</td>
          </tr>
          <tr>
            <td>FB JTN </td>
            <td>?utm_source=sf&utm_medium=social&utm_campaign=fbjtn</td>
          </tr>
          <tr>
            <td>FB JS </td>
            <td>?utm_source=sf&utm_medium=social&utm_campaign=fbjs</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br><br>

    
  </div>
</template>

<script>
import axios from 'axios'


export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return{
      rssblock:[],
      activeblock:[],
      postedblock:[],
      openposts:[],
      }
  },
  mounted() {
    this.cleanRSS();
    this.getRSS();
  },
  methods: {
    async cleanRSS() {
      await axios
      //  .get(`https://api.datanewsfeed.com/m3/cleanrss/`)
        .get(`/m3/cleanrss/`)
      .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].close=='Y') {this.postedblock.push(response.data[i])}
            if (response.data[i].close!='Y') {this.activeblock.push(response.data[i])}
              this.rssblock.push(response.data[i])
          }
      }).catch(error => {console.log(JSON.stringify(error))})
    },

    async submitPost(cindex) {
      var todayDate = new Date().toISOString().slice(0, 10);
      if (cindex.twjs === null){cindex.twjs ='-'}
      if (cindex.twjtn === null){cindex.twjtn ='-'}
      if (cindex.trujs === null){cindex.trujs ='-'}
      if (cindex.trujtn === null){cindex.trujtn ='-'}
      if (cindex.fbjs === null){cindex.fbjs ='-'}
      if (cindex.fbjtn === null){cindex.fbjtn ='-'}
      if (cindex.close === null){cindex.close ='-'}
      
      if (cindex.twjs == true){cindex.twjs ='Y'}
      if (cindex.twjtn == true){cindex.twjtn ='Y'}
      if (cindex.trujs == true){cindex.trujs ='Y'}
      if (cindex.trujtn == true){cindex.trujtn ='Y'}
      if (cindex.fbjs == true){cindex.fbjs ='Y'}
      if (cindex.fbjtn == true){cindex.fbjtn ='Y'}
      if (cindex.close == true){cindex.close ='Y'}

      let cpost = {}
        cpost['id']=cindex.id
        cpost['index']=cindex.index
        cpost['header']=cindex.header
        cpost['twjs']=cindex.twjs
        cpost['twjtn']=cindex.twjtn
        cpost['trujs']=cindex.trujs
        cpost['trujtn']=cindex.trujtn
        cpost['fbjs']=cindex.fbjs
        cpost['fbjtn']=cindex.fbjtn
        cpost['close']=cindex.close
        cpost['date']=cindex.date
        cpost['postid']=cindex.postid

      await axios
        //  .put(`https://api.datanewsfeed.com/m3/updateposts/?id=${cindex.postid}`, cpost)
          .put(`/m3/updateposts/?id=${cindex.postid}`, cpost)
          .then(response=>{this.pageRefresh()})
    },

    async getRSS() {
      this.openposts=[]
      await axios
       // .get(`https://api.datanewsfeed.com/m3/magic/`)
        .get(`/m3/magic/`)
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].posted==0) {this.openposts.push(response.data[i].id)}
          }
      }).catch(error => {console.log(JSON.stringify(error))})
    },

    
    async updateRSS(id) {
      let rsspost = {}
      rsspost['id']=id
      rsspost['posted']=1
      await axios
        //  .patch(`https://api.datanewsfeed.com/m3/rssup/?id=${id}`, rsspost)
          .patch(`/m3/rssup/?id=${id}`, rsspost)
          .then(response=>{this.pageRefresh()})
    },

    pageRefresh() {
      setTimeout(function(){location.reload(true)}, 1500);

    }

  }
}
</script>

<style>

.active, .collapsible:hover {
  background-color: #555;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
}
</style>
