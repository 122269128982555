<template>
  <div class="home bgrnd has-text-black ">
    <div class="section has-background-black has-text-white  mx-0">
      <div class="columns">
        <div class="column is-3">
          <figure class="image">
            <img src="@/assets/jtn.png" alt="">
          </figure>
          <a href="https://justthenews.com/" class="has-text-white is-size-6  ml-4 my-2">JustTheNews.com</a>
        </div>
        <div class="column is-size-4 has-text-centered">
        M3 Content Management
        <br> and JTN RSS Feeds
        </div>
      </div>
    </div>

    
    <div class="">
      <br><br>
      <div class="columns mx-0 ">
        <div class="column is-4 mx-0  has-text-left ">
          <div class="box">
          <h1 class="mx-4 is-size-5">
          <a v-on:click="runChat('active')" class="has-text-black"><b>M3 Internal Chat</b></a>
            <br>
            <a v-if="chatactive=='active'" v-on:click="runChat('inactive')" class="is-size-7">Minimize</a>
            <hr class="hd1">
          </h1>
          <template v-if="chatactive=='active'">
          <div class=" has-text-black mx-1">            
            <div class="flex-wrap" style="height:400px; overflow: auto; overflow-wrap: break-word;" id="chatb">
              
              <div class="px-2" v-for="item in chatblock" >
                <p class="">
                <b>{{item.name}}</b> {{item.created_at.slice(11,16)}}
                <br>{{item.text}}
                <hr>
                </p>
              </div>
            </div>
            <hr>
            {{}}
            <div class="field">
              <label class="label">Message</label>
              <div class="control">
                <textarea class="input textarea" type="text" placeholder="Text" v-model="chatbox.text" rows="3">
                </textarea>
              </div>
            </div>
            <a v-on:click="addChat(chatbox)" class="is-size-6 button btngrey has-text-white is-medium is-focused" >Send</a>
          </div>
          </template>
          <br>
        </div>
        

      </div>

      <div class="column is-7 mx-0 has-text-left ">
        <div class="box has-text-black" style="">
          <h1 class="mx-4 is-size-5" >
            <a v-on:click="rssactive='active'" class="has-text-black"><b>Active JTN Headlines</b></a>

            <br>
            <a v-if="rssactive=='active'" v-on:click="rssactive='inactive'" class="is-size-7">Minimize</a>
          </h1>
          <hr class="hd1">
          <template v-if="rssactive=='active'">
            <table class="table is-hoverable" >
              <div v-for="feed in activeblock" >
                <tbody>

                <div class="modal" id="modal1">
                  <div class="modal-background"></div>
                    <div class="modal-card">
                      <header class="modal-card-head">
                        <p class="modal-card-title">Add Creative</p>
                      </header>
                      <section class="modal-card-body">
                        <div class="columns">
                          <div class="column is-one-fifth">
                            <br>
                            <figure class="image is-128x128"><img :src="activemodal.media"></figure>
                            
                            <p class="is-size-7 has-text-left">
                              <br><br>
                              Category Tags:<br><i>{{activemodal.category}}</i>
                            </p>
                          </div>
                          <div class="column has-text-left ml-3">
                            <p class="has-text-left is-size-5 has-text-black">
                            <br>
                              <b>
                              <a v-bind:href="activemodal.index" class="has-text-black" target="_blank" v-html="activemodal.header"></a> 
                              </b>
                              <br>
                              <p class="has-text-left is-size-6 has-text-black">
                                <br>
                                {{activemodal.description}}
                              </p>
                            </p>
                            <br>
                            <p class="is-size-6 has-text-left">
                              <div class="field">
                                <label class="label is-size-7">Add Headline</label>
                                <div class="control">
                                  <textarea class="input textarea" type="text" placeholder="Text" v-model="activemodal.creative" rows="2">
                                  </textarea>
                                </div>
                              </div>
                              <div class="field">
                                <label class="label is-size-7">Add Comment</label>
                                <div class="control">
                                  <input class="input" type="text" name="comments" placeholder="comment" v-model="activemodal.comments">
                                </div>
                              </div>
                            </p>
                            <br>
                          </div>
                        </div>
                      Existing M3 Creatives (if available):
                      <p class="has-text-left is-size-5 has-text-black" v-for="blig in creativeblock.filter(art=>art.index== activemodal.index)">
                        <b>{{blig.creative}}</b>
                      </p>
                      </section>
                      <footer class="modal-card-foot">
                        <button class="button is-success" v-on:click="addCreative(activemodal)" >Submit Creative</button>
                        <button class="button" v-on:click="closeModal()" >Cancel</button>
                      </footer>
                    </div>
                  </div>

                  <tr>
                    <td class="has-text-left"> 
                      <br>
                      <a v-on:click="openModal(feed)" class="has-text-black has-text-weight-medium is-size-6">{{feed.header}}</a>
                      <hr align="left" width="70%" class="mb-0 mt-1">
                    </td>    
                  </tr>
                </tbody>
              </div>
            </table>
          </template>
        </div>
        <br>

        <div class="box has-text-black" style="">
          <h1 class="mx-4 is-size-5" >
              <a v-on:click="fullrss='active'" class="has-text-black"><b>RSS Feed</b></a>
            <br>
            <a v-if="fullrss=='active'" v-on:click="fullrss='inactive'" class="is-size-7">Minimize</a>
          </h1>
          <hr class="hd1">
          <template v-if="fullrss=='active'">

          <b>Set auto RSS refresh: &nbsp &nbsp inactive</b>

          <div class=" has-text-black mx-1" style="height:400px; overflow:auto;">   
            <table class="table is-size-6" >
              <div v-for="feed in rssblock" >
                <tbody>
                <div class="modal" id="modal1">
                  <div class="modal-background"></div>
                    <div class="modal-card">
                      <header class="modal-card-head">
                        <p class="modal-card-title">Add Creative</p>
                      </header>
                      <section class="modal-card-body">
                        <div class="columns">
                          <div class="column is-one-fifth">
                            <br>
                            <figure class="image is-128x128"><img :src="activemodal.media"></figure>
                            
                            <p class="is-size-7 has-text-left">
                              <br><br>
                              Category Tags:<br><i>{{activemodal.category}}</i>
                            </p>
                          </div>
                          <div class="column has-text-left ml-3">
                            <p class="has-text-left is-size-5 has-text-black">
                            <br>
                              <b>
                              <a v-bind:href="activemodal.index" class="has-text-black" target="_blank" v-html="activemodal.header"></a> 
                              </b>
                              <br>
                              <p class="has-text-left is-size-6 has-text-black">
                                <br>
                                {{activemodal.description}}
                              </p>
                            </p>
                            <br>
                            <p class="is-size-6 has-text-left">
                              <div class="field">
                                <label class="label is-size-7">Add Headline</label>
                                <div class="control">
                                  <textarea class="input textarea" type="text" placeholder="Text" v-model="activemodal.creative" rows="2">
                                  </textarea>
                                </div>
                              </div>
                              <div class="field">
                                <label class="label is-size-7">Add Comment</label>
                                <div class="control">
                                  <input class="input" type="text" name="comments" placeholder="comment" v-model="activemodal.comments">
                                </div>
                              </div>
                            </p>
                            <br>
                          </div>
                        </div>
                      Existing M3 Creatives (if available):
                      <p class="has-text-left is-size-5 has-text-black" v-for="blig in creativeblock.filter(art=>art.index== activemodal.index)">
                        <b>{{blig.creative}}</b>
                      </p>
                      </section>
                      <footer class="modal-card-foot">
                        <button class="button is-success" v-on:click="addCreative(activemodal)" >Submit Creative</button>
                        <button class="button" v-on:click="closeModal()" >Cancel</button>
                      </footer>
                    </div>
                  </div>

                  <tr>
                    <td class="has-text-left"> 
                      <br>
                      <a v-on:click="openModal(feed)" class="has-text-black has-text-weight-medium is-size-6">{{feed.header}}</a>
                      <hr align="left" width="70%" class="mb-0 mt-1">
                    </td>    
                  </tr>

                </tbody>
              </div>
            </table>
          </div>
          </template>
        </div>

      </div>

    </div>

    <div class="section mx-0 is-size-4 has-text-left">
      <div class="columns">
        <div class="column is-one-fifth">
        </div>
        <div class="column">
          <div class="box has-text-black" style="">
            <h1 class="is-size-5" >
                <a v-on:click="creativeactive='active'" class="has-text-black"><b>Recent M3 Headlines</b></a>
              <br>
              <a v-if="creativeactive=='active'" v-on:click="creativeactive='inactive'" class="is-size-7">Minimize</a>
            </h1>
            <hr class="hd1">
            <template v-if="creativeactive=='active'">
              <div class=" has-text-black mx-1" style="height:250px; overflow:auto;">   
                <table class="table is-hoverable" >
                  <div class="is-size-7 has-text-left" v-for="crpost in creativeblock" >
                    <tbody>
                      <tr>
                        <td class="has-text-left"> 
                          <br>
                          <a v-bind:href="crpost.index" class="has-text-black" target="_blank" v-html="crpost.creative"></a> 
                          <hr align="left" width="70%" class="mb-0 mt-1">
                        </td>    
                      </tr>
                    </tbody>
                  </div>
                </table>
              </div>
            </template>
          </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section mx-0 is-size-4 has-text-left">
      <div class="columns">
        <div class="column is-one-fifth">
        <b>Recent Posts</b>
        </div>
        <div class="column ">
          
          <br><br>



          <div class="mx-0 " v-for="item in postedblock" >
            <div class="box">
              <div class="columns">
                <div class="column is-one-fifth">
                  <figure class="image is-128x128">
                    <img :src="item.media">
                  </figure>
                  <p class="is-size-7 has-text-left">
                    <br>
                    <i>{{item.category}}</i>
                  </p>
                </div>
                <div class="column has-text-left">
                <p class="has-text-left is-size-5 has-text-black" v-for="blig in creativeblock.filter(art=>art.index== item.index)">
                    <b>{{blig.creative}}</b>
                  </p>
                  <hr>
                  <p class="has-text-left is-size-5 has-text-black">
                    <b>
                    <a v-bind:href="item.index" class="has-text-black" target="_blank" v-html="item.header"></a> 
                    </b>
                    <br>
                    <p class="has-text-left is-size-6 has-text-black">
                      <br>
                      {{item.description}}
                    </p>
                  </p>
                  <br>
                  </div>
                </div>
              </div>
              <br>
            </div>
          </div>



        </div>
      </div>
      
  </div>
  <br>

  <div class="column is-5">
    <div class="box boxgrey has-text-white has-text-right" style="height:300px; overflow:auto;">
      <b>Create New Post</b> 
      <div class="field has-text-left">
        <label class="">Header</label>
        <div class="control">
          <input class="input" type="text" placeholder="Add Header">
        </div>
      </div>
      <div class="field has-text-left">
        <label class="">Link</label>
        <div class="control">
          <input class="input" type="text" placeholder="Add Link">
        </div>
      </div>
      <div class="">
        <label class="checkbox">
          <input type="checkbox">
          Twitter
        </label>
        &nbsp
        <label class="checkbox">
          <input type="checkbox">
          FB
        </label>
        &nbsp
        <label class="checkbox">
          <input type="checkbox">
          Truth
        </label>
      </div>
      <br>
      <b>Post</b>
    </div>
  </div>



</template>

<script>
import axios from 'axios'


export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return{
      activeblock:[],
      postedblock:[],
      rssblock:[],
      creativeblock:[],
      chatblock:[],
      chatbox:[],
      statuses:{},
      timer:"",
      chatcnt:0,
      chatactive:'active',
      creativeactive:'active',
      rssactive:'active',
      fullrss:'active',
      activemodal:'',
      uname:'',

      }
  },
  created() {  
   // this.getChatcnt();
 //  if (this.chatactive=='active') {
 //   this.timer = setInterval(this.getChatcnt, 300000);  
 //   }
  },  
  mounted() {
    this.getRSS();
    this.getCreatives();
    this.getChatcnt();
    this.runChat('active')
  },

  methods: {
    openModal(feed) {
      this.activemodal=feed
      document.getElementById("modal1").classList.add("is-active");
      },
    closeModal() {document.getElementById("modal1").classList.remove("is-active");},

    runChat(chatstatus) {
      this.chatactive=chatstatus
      if (chatstatus=='active') { this.getChat()
        this.timer = setInterval(this.getChatcnt, 30000);
        } else 
          {this.timer = setInterval(this.getChatcnt, 500000000);}
    },
    
    async getRSS() {
      this.rssblock=[]
      await axios
      //  .get(`https://api.datanewsfeed.com/m3/magic/`)
        .get(`/m3/magic/`)
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
              this.rssblock.push(response.data[i])
              if (response.data[i].posted==0) {this.activeblock.push(response.data[i])}
              if (response.data[i].posted==1) {this.postedblock.push(response.data[i])}
          }
      }).catch(error => {console.log(JSON.stringify(error))})
    },

    async getStatus() {
      await axios
       // .get(`https://api.datanewsfeed.com/m3/cleanrss/`)
        .get(`/m3/cleanrss/`)
      .then(response => {
          for (let i = 0; i < response.data.length; i++) {
              this.statuses[response.data[i].index]= status=response.data[i].close
          }
      }).catch(error => {console.log(JSON.stringify(error))})
    },

    async getCreatives() {
      this.creativeblock=[]
      await axios
       // .get(`https://api.datanewsfeed.com/m3/creatives/`)
        .get(`/m3/creatives/`)
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
              this.creativeblock.push(response.data[i])
          }
      }).catch(error => {console.log(JSON.stringify(error))})
    },

    async addCreative(cindex) {
      var todayDate = new Date().toISOString().slice(0, 10);
      if (cindex.comments==null) {var comms = "-"}
      else {var comms = cindex.comments}
      if (cindex.creative==null) {var create = "-"}
      else {var create = cindex.creative}
      if (create == "-" && comms == "-") {console.log('missing entry')}
      else {
        let cpost = {}
          cpost['index']=cindex.index
          cpost['original']=cindex.header
          cpost['creative']=create
          cpost['comments']=comms
          cpost['date']=todayDate
          cpost['id']=cindex.id
        await axios
        //  .post(`https://api.datanewsfeed.com/m3/creatives/`, cpost)
          .post(`/m3/creatives/`, cpost)
          .then(response=>{this.pageRefresh()})
        }
    },

    async getChat() {
      this.chatblock=[]
      await axios
      //  .get(`https://api.datanewsfeed.com/m3/chats/`)
        .get(`/m3/chats/`)
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
              this.chatblock.push({'name': response.data[i].name, 'text': response.data[i].text, 'created_at': response.data[i].created_at})
          }
      }).catch(error => {console.log(JSON.stringify(error))})
      document.getElementById('chatb').scrollTop = 9999999;
    },
    async addChat(chatbox) {
      var todayDate = new Date().toISOString();
      let cpost = {}
        cpost['name']=this.$store.state.user.username 
        cpost['text']=chatbox.text
        cpost['created_at']=todayDate
      await axios
      //  .post(`https://api.datanewsfeed.com/m3/chats/`, cpost)
        .post(`/m3/chats/`, cpost)
        .then(response=>{this.getChat()})
        this.chatbox=[]
    },
    async getChatcnt() {
      let ccnt = this.chatcnt
      await axios
      //  .get(`https://api.datanewsfeed.com/m3/dbc/`)
        .get(`/m3/dbc/`)
      //  .then(response => { this.chatcnt= response.data.count
        .then(response=> { if (response.data.count > this.chatcnt) 
        {this.getChat()
        this.chatcnt= response.data.count}
        
      }).catch(error => {console.log(JSON.stringify(error))})
  //    if (ccnt< response.data.count ){this.getChat() }
    },


    pageRefresh() {
      setTimeout(function(){location.reload(true)}, 1000);

    }

  }
}
</script>

<style>

.active, .collapsible:hover {
  background-color: #555;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
}

.boxgrey {
  background-color: #2c333a !important
}

.backgrey {
  background-color:#2c333a !important
}

.btngrey {
  background-color: #293E5C !important
}

.hd1 {
  height: 4px;
  background-color: #2c333a;

}
</style>
