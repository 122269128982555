<template>
  <div id="wrapper">
    <nav class="navbar is-dark has-text-white">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item"><strong>Home</strong></router-link>
      </div>

      <div class="navbar-menu">
        <div class="navbar-end">
          <template v-if="$store.state.isAuthenticated">
            <router-link to="/dashboard" class="navbar-item">Dashboard</router-link>
            <div class="buttons">
                <button @click="logout()" class="button is-danger">Log out</button>
            </div>
          </template>

          <template v-else>
            <div class="navbar-item">
              <div class="buttons">
                <router-link to="/signin" class="button is-light">Log in</router-link>
              </div>
            </div> 
            
          </template>
        </div>
      </div>
    </nav>

      <router-view/>

    <footer class="footer">
    <button @click="clearcreds()" class="button is-clear">Flush creds</button>
    <br><br>
      <p class="has-text-centered">M3 CMS 2022</p>
    </footer>
  </div>
</template>





<script>
  import axios from 'axios'
  export default {
    name: 'App',
    beforeCreate() {
      this.$store.commit('initializeStore')
      const token = this.$store.state.token
      if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
      } else {
        axios.defaults.headers.common['Authorization'] = ""
      }
    },
    methods:{
      clearcreds() {
        localStorage.removeItem("username")
        localStorage.removeItem("userid")
        localStorage.removeItem("token")
        this.$store.commit('removeToken')
        this.$router.push('/')
      },
      logout() {
            axios
                .post("/api/v1/token/logout/")
                .then(response => {
                    axios.defaults.headers.common["Authorization"] = ""
                    localStorage.removeItem("username")
                    localStorage.removeItem("userid")
                    localStorage.removeItem("token")
                    this.$store.commit('removeToken')
                    this.$router.push('/')
                })
                .catch(error => {
                    if (error.response) {
                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.log(JSON.stringify(error.message))
                    } else {
                        console.log(JSON.stringify(error))
                    }
                })
        }
    }
  }
</script>


<style lang="scss">
@import '../node_modules/bulma';


#app {
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif; 
  font-family: serif, sans-serif, times, Helvetica;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}

.bgrnd {
//  background-color:#e8ebed !important;dfe3e7
  background-color:#dfe3e7 !important;
}

.frgrnd {
  background-color:#f1f2f4 !important;
}

</style>
